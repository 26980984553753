<template>
  <div  class="main" style="height:100vh;">

    <div class="row">
    <div class="col-md-4"></div>
    <div class="col-md-4"><img src="@/assets/img/pullover.jpg" style="width:100%;margin:auto;"/></div>
    <div class="col-md-4"></div>

    </div>
    <div style="text-align:center">
      Diese URL ist leider nicht mehr gültig.<br />
      Dein Hoodie wurde bereits verschickt. Du kannst keine Änderungen mehr vornehmen.

    </div>

  </div>
</template>
<script>
//   import { ElMessageBox } from 'element-plus';
import router from "../router";
export default {
  components: {},

  /*

 -------------------http://10.10.0.25:8080/6172e141068a9210aa7889c6
  */

  setup() {
    function goTo(target) {
      router.push({ path: target });
    }




    return {
      goTo,
    };
  },
  mounted() {
    // alert(1)
  },
};
</script>

<style scoped>

h1{
  font-size:50px;
  font-weight:bold;
  font-family:"TeleNeo",Calibri,"PT Sans Narrow",sans-serif
}
.row{
  margin-bottom:20px;
}
.main {
  margin: 0px;
  padding:10px;
    background-color:#e30074;
  color:#FFF;
}

template {
  padding: 20px;
}


</style>