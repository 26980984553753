<template>
  <div class="main">
    <!-- <router-link class="nav-link" to="/qrscanner"  tag="button">Jetzt QR Code Scannen</router-link>  -->





  <!-- <button class="btn btn-primary" @click="addNewEintrag()"><i class="el-icon-plus"></i></button> -->
<button class="btn btn-secondary" @click="reload()"><i class="el-icon-refresh"></i></button>

   

  <table class="table table-striped">
    <tr>
      <th>Datum</th>
      <th>Adresse</th>
    </tr>
     <!--@click="goTo('/bestellung/'+bestellung._id)"-->
    <tr class="cp" :key="shipment" v-for="shipment in shipments" >
      <td>{{shipment._id}}</td>
      <td><pre>{{shipment}}</pre></td>
    </tr>
  </table>
  



    
  </div>
</template>
<script>
import { inject ,ref} from "vue";
//   import { ElMessageBox } from 'element-plus';
import router from "../router";
export default {
  components: {},

  setup() {
    const web = inject("web");
    const moment = inject("moment");
    console.log("STUP");
    let shipments=ref([])

    function goTo(target) {
      router.push({ path: target });
    }



  
   function openTrackingURL(shipment){

     window.open(shipment.tracking_url)
   }


    function reload(){
      web.post("/shipments",{},(data) => {
        console.log(data)
        shipments.value=data.shipments

      })
    }

    reload()


  function getDate(date){
      console.log(date)
      return moment(date).format("DD.MM.YYYY")
  }
    
    return {
      goTo,
      shipments,
      reload,
      getDate,
      openTrackingURL
    };
  },
  mounted() {
    // alert(1)
  },
};
</script>

<style scoped>
.main {
  margin: 10px;
}
footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
template {
  padding: 20px;
}
.main {
  margin: 20px;
}
</style>