<template>
  <div class="main">
    <!-- <router-link class="nav-link" to="/qrscanner"  tag="button">Jetzt QR Code Scannen</router-link>  -->

    <!-- <button class="btn btn-primary" @click="addNewEintrag()"><i class="el-icon-plus"></i></button> -->
    <button class="btn btn-secondary" @click="reload()">
      <i class="el-icon-refresh"></i>
    </button>
   


      <input type="number" v-model="anzahlProSeite" class="form-control" style="width:4em;display:initial"/>
    
    <button class="btn btn-secondary" @click="setCurrentPage(-1)">
      <i class="el-icon-back"></i>
    </button>
     {{currentPage}} / {{getAnzPages()}}
    <button class="btn btn-secondary" @click="setCurrentPage(1)">
      <i class="el-icon-right"></i>
    </button>

    Bestellungen: {{ anzBestellungen }}
    <form @submit.prevent="reload()">

    <input
      type="text"
      v-model="suchstring"
      class="form-control"
      placeholder="Suchstring"
    />
    </form>

  
    <table class="table table-striped">
      <tr>
        <th>#</th>
        <th>Datum</th>
        <th>Typ</th>
        <th>E-Mail</th>
        <th>Sent</th>
      </tr>
      <!--@click="goTo('/bestellung/'+bestellung._id)"-->
      <tr class="cp" :key="kunde._id" v-for="(kunde,index) in doitkunden">
        <td>{{ getIndexNumber(index) }}</td>
        <td>{{ getDate(kunde.datum) }}</td>
        <td>{{ kunde.stammdaten.email }}</td>
        <td>{{ kunde.send_doitmailing2022_date }}</td>
      </tr>
    </table>
  </div>
</template>
<script>
// http://10.10.0.25:8080/61951b31f64fdb432279b9cf
//https://weihnachten.format-c.info/61951b31f64fdb432279b9cf
import { inject, ref } from "vue";
//   import { ElMessageBox } from 'element-plus';
import router from "../router";
export default {
  components: {},

  setup() {
    const web = inject("web");
    const moment = inject("moment");
    const notify = inject("notify");
    console.log("doitkunden");
    let doitkunden = ref([]);
    let suchstring = ref("");
    let anzahlProSeite = ref(50);
    let groessenfilter = ref("m");
    let currentPage = ref(1);
    let anzDoitkunden = ref(0);

    function goTo(target) {
      router.push({ path: target });
    }

    function reload(data) {

      if(typeof(data)=="undefined"){
        data={}
      }
      // web.post("/carriers", {}, (data2) => {
      //   console.log("data2");
      //   console.log(data2);
      // });

      var skipper = (currentPage.value-1) * anzahlProSeite.value
      var limit = anzahlProSeite.value
      var params={ suchstring: suchstring.value,skip:skipper,limit:limit };

      if(data.errorsOnly===true){
        params.errorsOnly=true
      }

      
      console.log(params)
      web.post("/doitkunden", params, (data) => {
        console.log(data);
        doitkunden.value = data.doitkunden;
        anzDoitkunden.value = data.anzDoitkunden;
      });
    }

    function sendTestmail(bestellung) {
      console.log("sendTestmail");
      console.log(bestellung);

      web.post("/sendTestmail", { bestellung: bestellung }, (data) => {
        notify({
          duration: 3000,
          ignoreDuplicates: false,
          title: "Email Verschickt",
          text: "MessageId: " + data.MessageId,
          type: "success",
        });
      });
    }


    reload();

    function getDate(date) {
      // console.log(date);
      return moment(date).format("DD.MM.YYYY");
    }

    function setCurrentPage(anz){
      if(currentPage.value + anz < 1){
        currentPage.value=1;
        return;
      }
      currentPage.value += anz

      reload()

      
    }

    function getIndexNumber(index){
      return ((currentPage.value-1)*anzahlProSeite.value)+index+1
    }

    function getAnzPages(){
      return Math.ceil(anzDoitkunden.value / anzahlProSeite.value)
    }

    return {
      goTo,
      doitkunden,
      reload,
      getDate,
      sendTestmail,
      suchstring,
      anzahlProSeite,
      groessenfilter,
      currentPage,
      setCurrentPage,
      getIndexNumber,
      anzDoitkunden,
      getAnzPages
    };
  },
  mounted() {
    // alert(1)
  },
};
</script>

<style scoped>
.main {
  margin: 10px;
}
footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
template {
  padding: 20px;
}
.main {
  margin: 20px;
}
</style>