<template>
  <div  class="main" style="height:100vh;">


    <div class="row">
    <div class="col-md-4"></div>
    <div class="col-md-4"><img src="@/assets/img/pullover.jpg" style="width:100%;margin:auto;"/></div>
    <div class="col-md-4"></div>

    </div>
    <div style="text-align:center">
      Diese URL ist leider nicht mehr gültig. Eine Bestellung war nur bis zum 31.12.2021 möglich.





    </div>

  </div>
</template>
<script>
import { ref,inject } from "vue";
import { useRoute } from 'vue-router'
//   import { ElMessageBox } from 'element-plus';
import router from "../router";
export default {
  components: {},

  /*

 -------------------http://10.10.0.25:8080/6172e141068a9210aa7889c6
  */

  setup() {
    // const storage = inject("storage");
    const web = inject("web");
    // const moment =inject("moment")
    const route = useRoute()

    let bestellung_id = route.params.bestellung_id;

    let bestellung=ref(null)
    let showDanke=ref(false)

    
    function submitBestellung(){
      web.post("/bestellen",{bestellung:bestellung.value,bestellung_id:bestellung_id},function(data){
        console.log(data);
        showDanke.value=true
      })
    }
    function reload(){
      web.post("/bestellung",{bestellung_id:bestellung_id},function(data){
        console.log("Beime Bestellung");
        console.log(data.bestellung);
        bestellung.value=data.bestellung;
      })
    }
reload()

    function goTo(target) {
      router.push({ path: target });
    }




    return {
      goTo,
      bestellung,
      submitBestellung,
      showDanke
    };
  },
  mounted() {
    // alert(1)
  },
};
</script>

<style scoped>

h1{
  font-size:50px;
  font-weight:bold;
  font-family:"TeleNeo",Calibri,"PT Sans Narrow",sans-serif
}
.row{
  margin-bottom:20px;
}
.main {
  margin: 0px;
  padding:10px;
    background-color:#e30074;
  color:#FFF;
}

template {
  padding: 20px;
}


</style>