<template>
<div class="main" id="footer">
  <div class="row " >
      <div class="col-sm-12">
          <div style="float:left">
                <img src="@/assets/img/footerImage.jpg" style="width:300px" />
          </div>
          <div style="float:right">
              <a href="/files/datenschutz.pdf" target="_blank">Datenschutz</a> | 
              <a href="https://www.telekom.de/impressum" target="_blank">Impressum</a>
          </div>
      </div>
    </div>
    
</div>

</template>

<script>

// import Button from './Button'
export default{


    name:"Footer",
    props:{
        randomValue :{type:Number,default:1}
    }
}
</script>

<style scoped>

footer > a {
    color:#FFF;
}
footer{
    color:#fff;
    justify-content: space-between;
    align-items: center;
    position:fixed;
    bottom:0px;
    background:#999;
    height:30px;
    font-size:12px;
    padding-left:20px;
    width:100%;
    padding-right:20px;
    }

    .main {
  margin: 0px;
  padding:10px;
    background-color:#e30074;
  color:#FFF;
}
a{
    color:#FFF
}
</style>