<template>
<div class="main"  v-if="bestellung!=null && typeof(bestellung.ausgefuellt_am)!='undefined'" >

    
     <div  class="main" style="height:100vh;">


        <div class="row">
          <div class="col-md-4"></div>
          <div class="col-md-4"><img src="@/assets/img/pullover.jpg" style="width:100%;margin:auto;"/></div>
          <div class="col-md-4"></div>
        </div>

        <div style="text-align:center">
        <!-- <img src="@/assets/img/titelzeile.jpg" style="width:100%;margin:auto;margin-bottom:50px"/> -->
        <!-- <p> -->
          Dieser Link ist nicht mehr gültig. <br />
          Du hast bereits am {{germanDate(bestellung.ausgefuellt_am)}} einen Pulli in Größe {{mapSize(bestellung.stammdaten.groesse)}} bestellt.
          <!-- </p> -->

        </div> 
    </div> 
</div>


  <div class="main" v-if="bestellung!=null && typeof(bestellung.ausgefuellt_am)=='undefined'">
    

    <img src="@/assets/img/titelzeile.jpg" style="width:100%;margin:auto;margin-bottom:50px"/>




    <div class="row" >
      <div class="col-md-6" style="text-align:center">

        <img src="@/assets/img/pullover2.jpg" style="width:80%;margin:auto;"/>
        <img src="@/assets/img/pullover_seite.jpg" style="width:80%;margin:auto;"/>
      </div>

      <div class="col-md-6">

        
        <div style="font-weight:bold;font-size:16x;text-align:justify;margin-bottom:20px;" v-if="bestellung!=null && typeof(bestellung.ausgefuellt_am)!='undefined' ">
        Dieser Link ist nicht mehr gültig. <br />
        Du hast bereits am {{germanDate(bestellung.ausgefuellt_am)}} einen Pulli in Größe {{mapSize(bestellung.stammdaten.groesse)}} bestellt.
        
        </div>

        <div v-if="bestellung!=null && bestellung.type=='pk'">
          <p class="bold">

            MAGENTA TV UND DISNEY+ WÜNSCHEN FROHE WEIHNACHTEN.
                                </p>

            <p class="bold">

Der Weihnachts-Hoodie aus dem letzten Jahr kam so gut bei Euch und
unseren Kunden an, dass wir stolz unsere Neuauflage präsentieren: das
Sweatshirt mit Olaf und Sven aus die Eiskönigin. Damit feiern wir ein
weiteres Jahr erfolgreiche Zusammenarbeit mit Disney+ und möchten
für eine gemütliche Stimmung in der kalten Jahreszeit sorgen.
                    </p>

            <p class="bold">

Und natürlich haben MagentaTV und Disney+ auch wieder einige
Weihnachtsüberraschungen im Programm – von bekannten Klassikern
über neuen Serien- und Film- Highlights frisch von der Kinoleinwand ist
so einiges dabei, was die Weihnachtszeit versüßt.
                    </p>

            <p class="">

Beschreibung Sweatshirt: Langarm, blau, unisex
Material: 85% Bio Cotton 15% Polyester (250 – 260 gr/m²)
Größenlauf: XS - 3XL
                    </p>

            <p class="bold">

Wir wünschen Euch viel Freude mit dem Sweatshirt und sagen
danke für Euren leidenschaftlichen Einsatz! Auf ein großartiges
Weihnachtsgeschäft!
                    </p>

            <p class="bold">

Bestellung ausschließlich für den Eigenbedarf.
Die Bestellung ist nur einmalig und bis einschließlich zum 31.12.2022
möglich.

</p>

        </div>



          <div v-if="bestellung!=null && bestellung.type=='pvg'">
          <p class="bold">
MAGENTA TV UND DISNEY+ WÜNSCHEN FROHE WEIHNACHTEN UND
EINEN GUTEN RUTSCH INS NEUE JAHR.
</p>

            <p class="bold">

Der Weihnachts-Hoodie aus dem letzten Jahr kam so gut bei Euch an,
dass wir dieses Jahr die Neuauflage als Pullover für Euch präsentieren.
Damit feiern wir ein weiteres Jahr erfolgreiche Zusammenarbeit
mit Disney und möchten mit Euch für festliche Stimmung in der
gemütlichen Jahreszeit sorgen.
</p>

            <p class="bold">

Auch 2022 haben MagentaTV und Disney+ wieder einige
Überraschungen im Programm. Von bekannten Klassikern bis hin
zu neuen Highlights – frisch von der Kinoleinwand. Hier ist für jeden
was dabei.
</p>

            <p class="bold">

Wir wünschen viel Freude mit dem Pullover, ein großartiges
Weihnachtsgeschäft und danken für Euren Day 1 Spirit und Euren
leidenschaftlichen Einsatz für unsere Kundinnen und Kunden.
</p>

            <p class="">

Beschreibung Sweatshirt: Langarm, blau, unisex
Material: 85% Bio Cotton 15% Polyester (250 – 260 gr/m²)
Größenlauf: XS - 3XL
</p>

            <p class="bold">

Bestellung ausschließlich für den Eigenbedarf.
Die Bestellung ist nur einmalig und bis einschließlich zum 31.12.2022 möglich.

                    </p>


              

        </div>


    
        <form @submit.prevent="submitBestellung()" v-if="bestellung!=null && typeof(bestellung.ausgefuellt_am)=='undefined'">
          <div class="row">
            <div class="col-sm-12"><label>Bitte Größe auswählen</label><br />
            <select class="form-control" style="width:200px" v-model="bestellung.stammdaten.groesse">
              <option value="">Bitte wählen</option>
              <!-- <option value="xs">XS</option> -->
              <option value="s">S</option>
              <option value="m">M</option>
              <option value="l">L</option>
              <option value="xl">XL</option>
              <!-- <option value="xxl">XXL</option> -->
              <!-- <option value="xxxl">XXXL</option> -->
              </select></div>
          </div>
          <div class="row">
            <div class="col-sm-6"><label>Vorname</label><br /><input type="text" class="form-control" v-model="bestellung.stammdaten.vorname" placeholder="" /></div>
            <div class="col-sm-6"><label>Nachname</label><br /><input type="text" class="form-control" v-model="bestellung.stammdaten.nachname" placeholder="" /></div>
          </div>

          <div class="row">
            <div class="col-sm-6"><label>Strasse</label><br /><input type="text" class="form-control" v-model="bestellung.stammdaten.strasse" placeholder="" /></div>
            <div class="col-sm-2"><label>Hausnummer</label><br /><input type="text" class="form-control" v-model="bestellung.stammdaten.hausnummer" placeholder="" /></div>
            <!-- <div class="col-sm-4"><label>E-Mail</label><br /><input type="text" class="form-control" v-model="bestellung.stammdaten.email" placeholder="E-Mail Adresse" /></div> -->
          </div>
          <div class="row">
            <div class="col-sm-4"><label>PLZ</label><br /><input type="text" class="form-control" v-model="bestellung.stammdaten.plz" placeholder="" /></div>
            <div class="col-sm-8"><label>Ort</label><br /><input type="text" class="form-control" v-model="bestellung.stammdaten.ort" placeholder="" /></div>
          </div>
          <div class="row">
            <div class="col-sm-4"><label>Land</label><br /><input type="text" class="form-control" v-model="bestellung.stammdaten.land" placeholder="" /></div>
          </div>

            <!-- <div class="row" style="margin-top:20px" v-if="bestellung.shipment && bestellung.shipment.errors && bestellung.shipment.errors.length==1 ">
            <div class="col-sm-12">
              Fehler: {{bestellung.shipment.errors[0]}}
            </div></div> -->
          <div class="row" style="margin-top:20px">
            <div class="col-sm-12">

              <!-- <label>Land</label><br /><input type="text" class="form-control" v-model="bestellung.stammdaten.land" placeholder="Land" /> -->
              <button style="width:100%;margin:0px;border:1px solid white" class="btn btn-magenta">Bestellung abschicken</button>
            </div>
          </div>
        </form> 
        
      </div>
    </div>
    
  </div>
</template>
<script>
import { ref,inject } from "vue";
import { useRoute } from 'vue-router'
//   import { ElMessageBox } from 'element-plus';
import router from "../router";
export default {
  components: {},

  /*

 -------------------http://10.10.0.25:8080/6172e141068a9210aa7889c6
  */

  setup() {
    const storage = inject("storage");
    const web = inject("web");
    // const moment = inject("moment");
    const moment =inject("moment")
    const route = useRoute()

    let bestellung_id = route.params.bestellung_id;

    let bestellung=ref(null)
    let showDanke=ref(false)

    var user =storage.get("user")
    console.log("user",user)

    
    function submitBestellung(){

      var s = bestellung.value.stammdaten
      if(
        s.vorname.trim() == "" ||
        s.nachname.trim() == "" ||
        s.strasse.trim() == "" ||
        s.plz.trim() == "" ||
        s.hausnummer.trim() == "" ||
        s.ort.trim() == "" ||
        s.land.trim() == "" ||
        s.groesse.trim() == "" 
        ){
          alert("Bitte fülle alle Felder aus.")
          return;
        }
      web.post("/bestellen",{bestellung:bestellung.value,bestellung_id:bestellung_id},function(data){
        console.log(data);

        
        showDanke.value=true
        goTo("danke")
      })
    }
    function reload(){

      // Diese URL ist leider nicht mehr gültig. Eine Bestellung war nur bis zum 31.12.2021 möglich.
      
      console.log("moment()")
      console.log(moment())
      let dann =moment("2022-12-31T23:00:00.000Z")

      if(moment() > dann){
        console.log('DNN')
        goTo("aktionsende")
        return;
      }




      web.post("/bestellung",{bestellung_id:bestellung_id},function(data){
        console.log("Beime Bestellung");
        console.log(data.bestellung);
        
        if(typeof(data.bestellung.shipment)!=="undefined" && typeof(data.bestellung.shipment.errors)=="undefined"){
        // if(typeof(data.bestellung.shipment)!=="undefined"){
          // goTo("versand")
          // return;
        }
        bestellung.value=data.bestellung;


      })
    }
reload()

    function goTo(target) {
      router.push({ path: target });
    }

    function germanDate(date){
      return moment(date).format("DD.MM.YYYY")
    }


    function mapSize(sizeSmall){
      return sizeSmall.toUpperCase()

    }


    return {
      goTo,
      bestellung,
      submitBestellung,
      showDanke,
      germanDate,
      mapSize
    };
  },
  mounted() {
    // alert(1)
  },
};
</script>

<style scoped>

h1{
  font-size:50px;
  font-weight:bold;
  font-family:"TeleNeo",Calibri,"PT Sans Narrow",sans-serif
}
.row{
  margin-bottom:20px;
}
.main {
  margin: 0px;
  padding:10px;
    background-color:#e30074;
  color:#FFF;
}

template {
  padding: 20px;
}
.bold{
  font-weight:bold;
}

</style>