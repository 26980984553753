<template>

<w-app>
  <!-- All your app content goes here. -->

  <!-- Also try to add a button! -->


  <!-- <Header title="Scanner" /> -->
   <Header v-if="$store.getters.isLoggedIn" />


  <router-view>
</router-view>
 
 <!-- {{user}} -->
  <Footer v-if="!user" :randomValue="randomValue" />


<notifications position="top center" />
<notifications group="auth" position="bottom right" />
<!-- {{tasks}} -->
</w-app>
</template>






<script>

import Header from './components/Header'
import Footer from './components/Footer'
// import useUser from './modules/User'

// import { useMouse, usePreferredDark,useLocalStorage  } from '@vueuse/core'

// import Tasks from './components/Tasks'
// import { Vue } from 'vue'
// import  Vue  from 'vue'
// import Task from './components/Task'


// import io from "socket.io-client";
// var socket = io.connect("http://localhost:4000" ,{transports: ['websocket']});


// import store from "@/store"
import {inject,ref,computed } from "vue"
import { useStore } from 'vuex'
export default {

setup(){
    const storage = inject("storage")
    const socket  = inject("socket")
    const store = useStore()
    
    // let user = store.get("user")

    // console.log(user)

    let randomValue = ref()
    
    socket.on("connect",() => {
      console.log("Socket Connected")
    })

    socket.on("disconnect",() => {
      console.log("Socket Disconnected")
    })




  // const isLoggedIn = computed(() => {
  //   console.log("store2")
  //   console.log(store2.user)
  //   return false;

  //   });

  // let user=ref(storage.user);



//  // tracks mouse position
//     const { x, y } = useMouse()
//     const isDark = usePreferredDark()

    // persist state in localStorage
    // const store = useLocalStorage(
    //   'my-storage', 
    //   {
    //     name: 'Apple',
    //     color: 'red',
    //   },
    // )

    // const store2 = useLocalStorage ('user')




    return {
      counter: computed(() => store.state.counter),
      user: computed(() => store.state.user),
      state: computed(() => store.state),
      $store:  store,
      storage,
      randomValue,
    }

  },
  // name: 'App',
  components: {
    Header,
    Footer,
    
  },




  data(){
    return {    }
  },
 computed:{
    
 },
 


  
}
</script>







<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  font-family: 'Arial, Verdana, sans-serif', sans-serif;
}
.container {
  /* max-width: 1500px; */

  margin: 30px auto;
  overflow: auto;
  min-height: 300px;
  border: 1px solid steelblue;
  padding: 11px;
  border-radius: 5px;
}
.btn {
  display: inline-block;
  /* background: #000; */
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
}
.btn:focus {
  outline: none;
}
.btn:active {
  transform: scale(0.98);
}
.btn-block {
  display: block;
  width: 100%;
}
</style>
