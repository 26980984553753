<template>
  <div class="main">


    <!-- <router-link class="nav-link" to="/qrscanner"  tag="button">Jetzt QR Code Scannen</router-link>  -->

    <!-- <button class="btn btn-primary" @click="addNewEintrag()"><i class="el-icon-plus"></i></button> -->
    <button class="btn btn-secondary" @click="reload()">
      <i class="el-icon-refresh"></i>
    </button>
    <button class="btn btn-secondary" @click="reload({errorsOnly:true})">
      Nur Fehler laden
    </button>

    <!-- <button class="btn btn-secondary" @click="addNewBestellung()"> -->
    <button class="btn btn-secondary" @click="addBestellung=true">
      <i title="Neuen Empfänger anlegen" class="el-icon-plus"></i>
    </button>

      <input type="number" v-model="anzahlProSeite" class="form-control" style="width:4em;display:initial"/>
    
    <button class="btn btn-secondary" @click="setCurrentPage(-1)">
      <i class="el-icon-back"></i>
    </button>
     {{currentPage}} / {{getAnzPages()}}
    <button class="btn btn-secondary" @click="setCurrentPage(1)">
      <i class="el-icon-right"></i>
    </button>

    Bestellungen: {{ anzBestellungen }}
    <form @submit.prevent="reload()">


    <div v-if="addBestellung===true">
      Neue Bestellung
      <label>E-Mail Adresse</label><br />
      <!-- <input     type="text"      v-model="newBestellung.email"      class="form-control"      placeholder="E-Mail Adresse"    /> -->
      <textarea     type="text"      v-model="newBestellung.email"      class="form-control"      placeholder="E-Mail Adressen"    ></textarea>
      <label>TYP</label><br />
      <select           v-model="newBestellung.type"      class="form-control"      placeholder="PVG oder PK"    >
        <option value="">Bitte wählen</option>
        <option value="pvg">PVG</option>
        <option value="pk">PK</option>
      </select>

    
    <button class="btn btn-danger" @click="addNewBestellung()">
      Bestellung hinzufügen
    </button>
    <button class="btn btn-secondary" @click="resetNewBestellung()">
      Abbrechen 
    </button>

    </div>
    <input
      type="text"
      v-model="suchstring"
      class="form-control"
      placeholder="Suchstring"
      v-if="addBestellung===false"
    />
    </form>

    <div
      v-if="currentBestellung != null && addBestellung===false"
      style="
        position: absolute;
        left: 0px;
        top: 0px;
        background-color: #fff;
        width: 100%;
      "
    >
     








 <h1>Empfänger-Daten</h1>
 <div>
      <div class="grid2" style="padding:10px">
        



            <select class="form-control g2"  v-model="currentBestellung.type" >
              <option value="">Bitte wählen</option>
              <option value="pvg">pvg</option>
              <option value="pk">pk</option>
            </select>

            <select class="form-control g2"  v-model="currentBestellung.stammdaten.groesse" >
              <option value="">Bitte wählen</option>
              <option value="xs">XS</option>
              <option value="s">S</option>
              <option value="m">M</option>
              <option value="l">L</option>
              <option value="xl">XL</option>
              <option value="xxl">XXL</option>
              <option value="xxxl">XXXL</option>
            </select>
            <div><label>Vorname</label><input type="text" class="form-control" v-model="currentBestellung.stammdaten.vorname" placeholder="Vorname" /></div>
            <div><label>Nachname</label><input type="text" class="form-control" v-model="currentBestellung.stammdaten.nachname" placeholder="Nachname" /></div>
            <div class="g2"><label>E-Mail</label><input type="text" class="form-control" v-model="currentBestellung.stammdaten.email" placeholder="E-Mail Adresse" /></div>
            <div><label>Straße</label><input type="text" class="form-control" v-model="currentBestellung.stammdaten.strasse" placeholder="Straße" /></div>
            <div><label>Hausnummer</label><input type="text" class="form-control" v-model="currentBestellung.stammdaten.hausnummer" placeholder="Hausnummer" /></div>
            <div><label>PLZ</label><input type="text" class="form-control" v-model="currentBestellung.stammdaten.plz" placeholder="PLZ" /></div>
            <div><label>Ort</label><input type="text" class="form-control" v-model="currentBestellung.stammdaten.ort" placeholder="Ort" /></div>
            <div class="g2"><label>Land</label><input type="text" class="form-control" v-model="currentBestellung.stammdaten.land" placeholder="Land" /></div>


      <button  class="g2 btn btn-success" @click="saveCurrentBestellung()">Speichern</button>

        
      </div>

      
</div>




      <h1>Aktuelles Shipment</h1>
      <table class="table table-striped">
        <tr>
          <td>ID:</td>
          <td>{{ currentBestellung.id }}</td>
        </tr>
        <tr>
          <td>carrier_tracking_no:</td>
          <td>
            {{ currentBestellung.carrier_tracking_no }} ({{
              currentBestellung.carrier
            }})
          </td>
        </tr>
        <tr>
          <td>label_url:</td>
          <td>
            <a target="_blank" :href="currentBestellung.label_url">{{
              currentBestellung.label_url
            }}</a>
          </td>
        </tr>
        <tr>
          <td>tracking_url:</td>
          <td>
            <a target="_blank" :href="currentBestellung.tracking_url">{{
              currentBestellung.tracking_url
            }}</a>
          </td>
        </tr>
        <tr>
          <td>reference_number:</td>
          <td>{{ currentBestellung.reference_number }}</td>
        </tr>
        <tr v-if="currentBestellung.to">
          <td>To</td>
          <td>
            {{ currentBestellung.to.first_name }}
            {{ currentBestellung.to.last_name }}<br />
            {{ currentBestellung.to.street }} {{ currentBestellung.to.street_no
            }}<br />
            {{ currentBestellung.to.zip_code }} {{ currentBestellung.to.city
            }}<br />
            {{ currentBestellung.to.country }}<br />
          </td>
        </tr>
        <tr
          class="cp"
          :key="paket.id"
          v-for="paket in currentBestellung.packages"
        >
          <td>Status:</td>
          <td>
            <div
              :key="tracking_event.timestamp"
              v-for="tracking_event in paket.tracking_events"
            >
              {{ tracking_event.status }} ({{ tracking_event.details }})
            </div>
          </td>
        </tr>
      </table>
      <button class="btn btn-success" @click="currentBestellung = null">
        Schliessen
      </button>


      <button class="btn btn-danger" @click="resetBestellung(currentBestellung)">
        Reset Bestellung
      </button>

      <button class="btn btn-danger" @click="resetTicket(currentBestellung)">
        Ticket zurücknehmen
      </button>

      <!--
         <pre>

        {{currentBestellung}}
        </pre> -->
    </div>

    <table class="table table-striped" v-if="addBestellung===false">
      <tr>
        <th>#</th>
        <th>Datum</th>
        <th>Typ</th>
        <th>Vorname</th>
        <th>Nachname</th>
        <th>Adresse</th>
        <th>Größe</th>
        <th></th>
        <th></th>
        <th></th>
      </tr>
      <!--@click="goTo('/bestellung/'+bestellung._id)"-->
      <tr class="cp" :key="bestellung._id" v-for="(bestellung,index) in bestellungen">
        <td>{{ getIndexNumber(index) }}</td>
        <td>{{ getDate(bestellung.datum) }}</td>
        <td>{{ bestellung.type }}</td>
        <td>{{ bestellung.stammdaten.vorname }}</td>
        <td>{{ bestellung.stammdaten.nachname }}</td>
        <td>
          {{ bestellung.stammdaten.strasse }}
          {{ bestellung.stammdaten.hausnummer }}<br />
          {{ bestellung.stammdaten.plz }}
          {{ bestellung.stammdaten.ort }}<br />
          {{ bestellung.stammdaten.land }}
        </td>
        <td>{{ bestellung.stammdaten.groesse }}</td>
        <td>
          <div v-if="bestellung.shipment && bestellung.shipment.errors">
            {{ bestellung.shipment.errors }}
            </div>
          </td>

        <td>
          <button
            class="btn btn-success"
            @click="versandticketErstellen(bestellung)"
          >
            Ticket drucken
          </button>
        </td>
        <td>
          <button
            class="btn btn-secondary"
            v-if="bestellung.shipment"
            @click="openTrackingURL(bestellung.shipment)"
          >
            Tracking
          </button>
        </td>
        <td>
          <button
            class="btn btn-success"
            @click="sendMail(bestellung,'confirm')"
          >
            Versandmail verschicken
          </button>
        </td>
        <td>
          <button
            class="btn btn-secondary"
            v-if="bestellung.shipment"
            @click="bestellungAbrufen(bestellung.shipment.id)"
          >
            Status
          </button>
        </td>
        <td>
          <button class="btn btn-secondary" @click="goTo('/' + bestellung._id)">
            Edit als User
          </button>
        </td>
        <td>
          <button class="btn btn-secondary" @click="setCurrentBestellung(bestellung)">
            Edit als Admin
          </button>
        </td>
        <td>
          <!-- <button class="btn btn-secondary" @click="sendTestmail(bestellung)">Testmail {{bestellung.stammdaten.email}}</button> -->
          <!-- <router-link class="nav-link" to="/" data-toggle="collapse"  data-target="#myNavBar">Home</router-link>  -->
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
// http://10.10.0.25:8080/61951b31f64fdb432279b9cf
//https://weihnachten.format-c.info/61951b31f64fdb432279b9cf
import { inject, ref } from "vue";
//   import { ElMessageBox } from 'element-plus';
import router from "../router";
export default {
  components: {},

  setup() {
    const web = inject("web");
    const moment = inject("moment");
    const notify = inject("notify");
    console.log("STUP");
    let bestellungen = ref([]);
    let currentBestellung = ref(null);
    let suchstring = ref("");
    let anzahlProSeite = ref(50);
    let groessenfilter = ref("m");
    let currentPage = ref(1);
    let anzBestellungen = ref(0);
    let addBestellung = ref(false);
    let newBestellung = ref({
      email:"",
      type:""
    });

    function goTo(target) {
      router.push({ path: target });
    }

    function openTrackingURL(shipment) {
      window.open(shipment.tracking_url);
    }


    function sendMail(bestellung,typ) {

      web.get("/sendmail/"+ bestellung._id+"/"+typ,function(data){
        console.log(data)
      })

        
    }

    function versandticketErstellen(bestellung) {
      if (bestellung.shipment) {
        if (bestellung.shipment.errors) {
          if(!confirm('Wollen Sie erneut versuchen ein Ticket dieses Datensatzes zu drucken?')) return;
            // alert(bestellung.shipment.errors[0]);
          
        }else{
          window.open(bestellung.shipment.label_url);
          return;
        }


        
      }
      web.post(
        "/versandticketErstellen",
        { bestell_id: bestellung._id },
        (data) => {
          window.open(data.shipment.label_url);
          bestellung.shipment = data.shipment;
        }
      );
    }

    function addNewBestellung() {

      if(newBestellung.value.email.trim=="" || newBestellung.value.type==""){
        console.log("Bitte E-Mail Adresse und Typ angeben")
        return;
      }
      console.log("newBestellung")
      console.log(newBestellung)
      // if (!confirm("Bist Du sicher, dass Du eine neue Bestellung anlegen möchtest?")) return false;

        let emails = newBestellung.value.email.split("\n")
        let emailArray = emails.filter(em => em.trim() !="").map(email => email.toLowerCase())

        newBestellung.value.emailArray=emailArray
      
        console.log("Anzahl Adressen:"+emailArray.length)
        console.log(emailArray)

        if (!confirm("Bist Du sicher, dass Du "+emailArray.length+" neue Bestellung anlegen möchtest?")) return false;

      
      web.post("/addNewBestellung", {newBestellung:newBestellung.value}, (data) => {
        console.log(data);
        // bestellungen.value=data.bestellungen
        bestellungen.value.push(data.neueBestellung);
        resetBestellung()
      });
    }

    function reload(data) {

      if(typeof(data)=="undefined"){
        data={}
      }
      // web.post("/carriers", {}, (data2) => {
      //   console.log("data2");
      //   console.log(data2);
      // });

      var skipper = (currentPage.value-1) * anzahlProSeite.value
      var limit = anzahlProSeite.value
      var params={ suchstring: suchstring.value,skip:skipper,limit:limit };

      if(data.errorsOnly===true){
        params.errorsOnly=true
      }

      
      console.log(params)
      web.post("/bestellungen", params, (data) => {
        console.log(data);
        bestellungen.value = data.bestellungen;
        anzBestellungen.value = data.anzBestellungen;
      });
    }

    function sendTestmail(bestellung) {
      console.log("sendTestmail");
      console.log(bestellung);

      web.post("/sendTestmail", { bestellung: bestellung }, (data) => {
        notify({
          duration: 3000,
          ignoreDuplicates: false,
          title: "Email Verschickt",
          text: "MessageId: " + data.MessageId,
          type: "success",
        });
      });
    }

    function bestellungAbrufen(id) {
      console.log("shipment");
      console.log(id);
      web.post("/bestellungAbrufen", { shipment_id: id }, (data) => {
        console.log(data);
        currentBestellung.value = data.shipment;
      });
    }

    reload();

    function getDate(date) {
      // console.log(date);
      return moment(date).format("DD.MM.YYYY");
    }

    function setCurrentPage(anz){
      if(currentPage.value + anz < 1){
        currentPage.value=1;
        return;
      }
      currentPage.value += anz

      reload()

      
    }

    function getIndexNumber(index){
      return ((currentPage.value-1)*anzahlProSeite.value)+index+1
    }

    function getAnzPages(){
      return Math.ceil(anzBestellungen.value / anzahlProSeite.value)
    }

    function setCurrentBestellung(bestellung){
      currentBestellung.value=bestellung
      
    }




  function resetTicket(){

      if(!confirm("Sicher, dass Du Du das Ticket für diese Bestellung löschen mÖchtest?")) return;
      console.log("resetTicket")
      
       web.post("/resetTicket", { bestellung_id:currentBestellung.value._id }, (data) => {
        console.log(data);
        currentBestellung.value = null;
        reload()
      });
    }

    function resetBestellung(){

      if(!confirm("Sicher, dass Du Du die Bestellung resetten willst?")) return;
      console.log("resetBestellung")
      
       web.post("/resetBestellung", { bestellung_id:currentBestellung.value._id }, (data) => {
        console.log(data);
        // currentBestellung.value = null;
      });
    }
    function saveCurrentBestellung(){

      console.log("saveCurrentBestellung")
      
       web.post("/saveCurrentBestellung", { bestellung:currentBestellung.value }, (data) => {
        console.log(data);
        currentBestellung.value = null;
      });

    }

    function resetNewBestellung(){

      addBestellung.value=false
      newBestellung.value.email=""
      newBestellung.value.type=""
    }

    return {
      goTo,
      bestellungen,
      reload,
      addNewBestellung,
      addBestellung,
      getDate,
      versandticketErstellen,
      setCurrentBestellung,
      openTrackingURL,
      bestellungAbrufen,
      currentBestellung,
      sendTestmail,
      suchstring,
      anzahlProSeite,
      groessenfilter,
      currentPage,
      setCurrentPage,
      getIndexNumber,
      anzBestellungen,
      getAnzPages,
      sendMail,
      saveCurrentBestellung,
      resetBestellung,
      newBestellung,
      resetNewBestellung,
      resetTicket
    };
  },
  mounted() {
    // alert(1)
  },
};
</script>

<style scoped>
.main {
  margin: 10px;
}
#footer {
  display: none !important;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
template {
  padding: 20px;
}
.main {
  margin: 20px;
}
</style>