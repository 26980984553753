<template>
  <div class="main">


<div style="background-color:#FFF" v-if="currentShortcut!==null">
    <form @submit.prevent="saveShortcut();">


          <label>Kürzel</label>
          <input type="text" class="form-control" v-model="currentShortcut.kuerzel" >


           
          <label>Text</label>
           <textarea v-model="currentShortcut.text" class="form-control"></textarea>

           <label>Kategorie</label>
           <select v-model="currentShortcut.kategorie" class="form-control">
             <option value="">Bitte Kategorie wählen</option>
             <option value="Service">Service</option>
             <option value="Reparaturen">Reparaturen</option>
             <option value="Sonstiges">Sonstiges</option>
           </select>
   
        <button type="submit" class="btn btn-primary" >Speichern</button>
        <button type="button" class="btn btn-default" @click="currentShortcut=null">Abbrechen</button>
    </form>
    


</div>





<div v-if="currentShortcut==null">
  <button class="btn btn-primary" @click="reload()">Neu laden</button>
  <button class="btn btn-primary" @click="addNewShortcut()">Neues Kürzel erstellen</button>
  <table class="table table-striped">
    <tr>
      <th>Kürzel</th>
      <th>Text</th>
      <th>Typ</th>
    </tr>
    <tr class="cp" :key="shortcut._id" v-for="shortcut in shortcuts"  v-bind:class="{ 'not_checked_rl': shortcut.kategorie=='0' ,'checked_rl': shortcut.kategorie=='1' }" @click="currentShortcut=shortcut">
      <td>{{shortcut.kuerzel}}</td>
      <td>{{shortcut.text}}</td>
      <td>{{shortcut.kategorie}}</td>
    </tr>
  </table>

</div>

    
  </div> <!-- Ende class main -->
</template>
<script>
import { inject, ref } from "vue";
import router from "../router";
export default {
  components: {},

  setup() {
    const storage = inject("storage");
    const web = inject("web");
    
    let shortcuts = ref([]);
    let currentShortcut = ref(null);

    

    function goTo(target) {
      router.push({ path: target });
    }
    

    function addNewShortcut(){

       web.post("/addNewShortcut",{},(data) => {
        console.log("addNewShortcut");
        console.log(data);
        shortcuts.value.unshift(data.neuesShortcut)
        
      })
    }
    function saveShortcut(){
      console.log(currentShortcut.value)

      web.post("/saveShortcut",{shortcut:currentShortcut.value},(data) => {
        console.log(data);
        currentShortcut.value = null;
        reload()
      })
      
    }
    function reload(){
      web.get("/shortcuts",(data) => {
        console.log(data)
        shortcuts.value=data.shortcuts
        storage.set("shortcuts",data.shortcuts)
      })
    }

    
     reload();

    return {
      saveShortcut,
      addNewShortcut,
      shortcuts,
      reload,
      goTo,
      currentShortcut
    };
  },
  mounted() {
    // alert(1)
  },
};
</script>

<style scoped>
.main {
  margin: 10px;
}
footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
template {
  padding: 20px;
}
.main {
  margin: 20px;
}
</style>