import '@babel/polyfill'
import 'mutationobserver-shim'
import {  createApp  } from 'vue'
import App from './App.vue'

import WaveUI from 'wave-ui'
import 'wave-ui/dist/wave-ui.css'



var app = createApp(App)



new WaveUI(app, {
  // Some Wave UI options.
})


import router from "./router"
// import Notifications from 'vue-notification'

//https://github.com/kyvg/vue3-notification
import Notifications from '@kyvg/vue3-notification'



import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap/dist/css/bootstrap.min.css'

window._ = require('lodash');

import './assets/css/global.css';

import '../node_modules/bootstrap/dist/js/bootstrap.min.js';
import '../node_modules/jquery/dist/jquery.min.js';

import axios from 'axios'
// axios.defaults.withCredentials = true

// import { VueCookieNext } from 'vue-cookie-next'
// import VueAxios from 'vue-axios'
// app.use(VueAxios, axios)
// app.use(VueCookieNext)

import io from "socket.io-client";
// const socket =  io.connect("http://localhost:4000" ,{transports: ['websocket']})
// const socket =  io.connect("http://172.28.23.243:4000" ,{transports: ['websocket']})
const socket =  io.connect("https://xmasversand.de",{transports: ['websocket']})

app.provide('socket', socket);



app.use(Notifications)
app.use(router);


import { notify } from "@kyvg/vue3-notification";
app.provide('notify', notify);

import moment from 'moment';
moment.locale('de');
app.provide('moment', moment);

import _ from 'lodash';
app.provide('lodash', _);

import web from "@/services/web"
app.provide('web', web);

// LocalStorage
import  storage from  "@/services/storage"
app.provide('storage', storage);


// Vuex
import  xstore  from '@/store/index'
app.use(xstore)



// import Vue from "vue";
// 
import QrcodeReaderVue3 from "qrcode-reader-vue3";
app.use(QrcodeReaderVue3);

// import Vue from "vue";
// import QrcodeReaderVue3 from "qrcode-reader-vue3";
// Vue.use(QrcodeReaderVue3);



// const meinStore = useStore()
// console.log("meinStore")
// console.log(meinStore)
//meinStore.state.user.name="CLSD"






// app.provide('storage', Vue.observable(storage));

// import useUser from './modules/User'
// app.provide('useUser', useUser);

import  store from  "@/store"
app.provide('store', store);

// app.config.globalProperties.$cclevervar = {data:"111"}


import VueGoogleCharts from "vue3-googl-chart";
app.use(VueGoogleCharts);


axios.interceptors.request.use(
    (request) => {

      // console.log("Methoid: "+request.method)

      request.credentials = true
      let user = storage.get("user");

      if(user!==null && typeof(user)!="undefined"){
        request.headers['x-session-token']=user.jwt;
      }

      let promoter = storage.get("promoter");

      if(promoter!==null && typeof(promoter)!="undefined"){
        request.headers['x-session-token-promoter']=promoter.jwt;
      }


      return request;
    }
  );

  axios.interceptors.response.use(
    (response) => {
      // do something with request meta data, configuration, etc
        // console.log("response")
        // console.log(response.headers)


      // dont forget to return request object,
      // otherwise your app will get no answer
      return response;
    },
    function (error) {
      // Do something with response error

      console.log("error ss")
      console.log(error)
      console.log("error.response.status")
      console.log(error.response.status)

      if(error.response.status == 401){
        console.log("Leite um zum Login");
        localStorage.removeItem("user");
        localStorage.removeItem("promoter");
        xstore.state.user= false
        xstore.state.promoter=false
  
        router.push("/login");
        return;
      }

      

      notify({
        duration:10000,
        ignoreDuplicates:true,
        title: "Es ist ein Fehler aufgetreten",
        text:error.response.data.error.message+"  ("+error.response.status+") ",
        type:"error",
    });

    

      console.log("Response Error")
      console.log(error.response.status);
      console.log(error.response);
      return Promise.reject(error);
    }
  );



  import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';
import './element-variables.scss'
// import lang  from 'element-plus/lib/locale/lang/de-de'
// locale(lang)

app.use(ElementPlus)


router.app = app
app.config.globalProperties.gdata = {foo:'bar'}
app.mount('#app')




