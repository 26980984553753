import {createRouter,createWebHistory} from 'vue-router' 
// import {inject} from 'vue' 

 import Shortcuts from '../views/Shortcuts'
 import Login from '../views/Login'
 import Danke from '../views/Danke'
 import Home from '../views/Home'
 import Bestellungen from '../views/Bestellungen'
 import Shipments from '../views/Shipments'
 import Doitkunden from '../views/Doitkunden'
 import Versand from '../views/Versand'
 import Aktionsende from '../views/Aktionsende'
//  import h from '@/plugins/helperPlugin'
 import h from '@/services/helpers'
import  storage from  "@/services/storage"

const routes = [
{
  path: "/doitkunden",
  name: "Doitkunden",
  component: Doitkunden,
  meta: { requiresAuth: true },
},
  {
    path: "/shortcuts",
    name: "Shortcuts",
    component: Shortcuts,
    meta: { requiresAuth: true },
  },
  {
    path: "/:bestellung_id",
    name: "home",
    component: Home,
    meta: { requiresAuth: false },
  },

  {
    path: "/bestellungen",
    name: "Bestellungen",
    component: Bestellungen,
    meta: { requiresAuth: false },
  },
  {
    path: "/shipments",
    name: "Shipments",
    component: Shipments,
    meta: { requiresAuth: false },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { requiresAuth: false },
  },
  {
    path: "/danke",
    name: "danke",
    component: Danke,
    meta: { requiresAuth: false },
  },{
    path: "/aktionsende",
    name: "aktionsende",
    component: Aktionsende,
    meta: { requiresAuth: false },
  },
  {
    path: "/versand",
    name: "versand",
    component: Versand,
    meta: { requiresAuth: false },
  }

]

// let isLoggedIn = function(){

//      console.log("isLoggedIn")
//      let user = localStorage.getItem("user");
//      if(user!==null && typeof(user)!="undefined"){
//          return true;
//      }
 
//      return false;
//  }

 const router = createRouter({
     history:createWebHistory(process.env.BASE_URL),
     routes,
    })
    router.replace({ path: '*', redirect: '/abgleichmaerkte' })

    router.beforeEach((to, from, next) => {

     // console.log("beforeEach",to)
     if (to.matched.some(record => record.meta.requiresAuth_promoter)) {
          console.log("Yeachh. I neede a promoter auth")

          // const storage =inject("storage")
          console.log('storage.get("user"');
          if(storage.get("promoter") ==  null){

               next({ name: 'Login' })
          }else{
               next()
          }
     }


     if (to.matched.some(record => record.meta.requiresAuth)) {
       // this route requires auth, check if logged in
       // if not, redirect to login page.

     //   console.log("123")
       if (!h.isLoggedIn()) {
         next({ name: 'Login' })
       } else {
         next() // go to wherever I'm going
       }
     } else {
       next() // does not require auth, make sure to always call next()!
     }
   })

    export default router