<template>
    <div class="container">
    <form >
    <div class="row m-1">
        <div class="col-md-12">
            <label >Username</label>
            <input type="text" class="form-control"  v-model="user.username">
        </div>
    </div>
    <div class="row m-1">
        <div class="col-md-12">
            <label >Passwort</label>
            <input type="password" class="form-control"  v-model="user.password">
        </div>
    </div>
    <div class="row m-1">
        <div class="col-md-12">
            
            <button type="submit" @click="doLogin" class="btn btn-primary">Login</button>
        </div>
    </div>
    </form>
         </div>   
    
    
</template>






<script>

// var post = (path,object ,callback) => {
// http.request({
//   url: "http://172.29.63.84:8080/login"+path,
//   method: "POST",
//   headers: { "Content-Type": "application/json" },
//   content: JSON.stringify(object)
// }).then(response => {
//   var result = response.content.toJSON();
//   callback(result);
// }, error => {
//   console.error(error);
// });


// }
import { useStore } from 'vuex'
import router from '../router'
// import h from '@/plugins/helperPlugin'
// import h from '@/services/helpers'
import { inject } from 'vue'

export default{
    setup(){
        
        const web =inject("web")
        const storage =inject("storage")
        const store = useStore()

        let  user ={
                username:"",
                password:""
            }
         function doLogin(e){

            // console.log("login")
            // console.log(this.user)
            web.post("/login",this.user,(data) => {
                console.log("My data 123,data",data)
                if(data.user){
                    storage.set("user",data.user)
                    store.state.user=data.user;
                    router.push("/bestellungen")
                }
                 
            })

            e.preventDefault()

        }
        return {
            user,
            doLogin
            }
    
    }
}












</script>

<style scoped>
.container{
    max-width:500px;
    border:1px solid #ddd;
    background:#eee;
    height:100px;
}
.btn{
    margin-left:0px;
    width:100%
}
</style>